import Header from "../components/Header";
import SEO from "../components/SEO";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { ContactForm } from "../components/ContactForm";


export default function ContactUs() {
    return (
        <div>
            <SEO
                title="Contact us | Alt Fire" 
                description="Get in touch with us and speak with a chartered fire engineer about your next project."
                name="Contact us | Alt Fire"
                type="website"
            />
            <Header title="Get in touch" subheading="" section="Contact us" />
            <Container className="main-content">
                <Row className="justify-content-start">
                 <Col xs={12} md={6}>
                        <h3>Registered office</h3>
                        <address>71-75 Shelton Street<br />Covent Garden<br />London<br />WC2H 9JQ</address>
                    </Col>
                </Row>
                <Row className="justify-content-start mt-3">
                    <Col xs={12} xl={8}>
                        <Row>
                            <Col xs={12} md={6}>
                                <h3>London</h3>
                                <p><a href="tel:+4402036334244">+44 (0) 203 633 4244</a></p>
                            </Col>
                            <Col xs={12} md={6}>
                                <h3>Manchester</h3>
                                <p><a href="tel:+4401613273866">+44 (0) 161 327 3866</a></p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={8}>
                                <h2>Email us</h2>
                                <ContactForm />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export function ContactUsSummary() {
    return (
        <div className="summary-section">
            <Container>
                <Row>
                    <Col>
                        <h2 className="text-center">Find us in London and Manchester</h2>
                        <p className="text-center">But wherever you're located, we're ready to help. </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text-center mt-2 mb-0"><LinkContainer to="contact-us"><Button variant="primary">Get in touch</Button></LinkContainer></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}