import React, {useState} from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import './ContactForm.css'

export const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [hasSent, setHasSent] = useState(false);
    const [showSentNotification, setShowSentNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [buttonText, setButtonText] = useState("Send enquiry");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const formSubmit = (e) => {
        e.preventDefault()
        setIsButtonDisabled(true);
        setButtonText("Sending...");
      
        let data = {
            name: name,
            email: email,
            phone: phone,
            message: message
        }
        
        axios.post('/api/v1', data)
        .then( res => {
            setHasSent(true);
            setShowSentNotification(true);
            console.log(res);
            //reset form
            resetForm();
        })
        .catch( () => {
            setHasSent(false);
            setShowErrorNotification(true);
            //console.log('Message not sent')
        })
    }

    const resetForm = () => {
        setName("");
        setMessage("");
        setEmail("");
        setPhone("");
        setButtonText("Send enquiry");
        setIsButtonDisabled(false);
    }

    return (
        <Form className="contact-form" onSubmit={ (e) => formSubmit(e)}>

            {hasSent && showSentNotification ? 
                <Alert variant="success" onClose={() => setShowSentNotification(false)} dismissible>
                    <Alert.Heading>Your enquiry has been sent</Alert.Heading>
                    <p>
                    Thank you, your enquiry has been sent to the Alt Fire team. We'll get back to you as soon as possible.
                    </p>
                </Alert>
            : null}

            {!hasSent && showErrorNotification ? 
                <Alert variant="danger" onClose={() => setShowErrorNotification(false)} dismissible>
                    <Alert.Heading>Something went wrong...</Alert.Heading>
                    <p>
                    Sorry we were unable to send your enquiry. Please contact us on +44 7745 956 808 or <a href="mailto:info@altfire.co.uk">info@altfire.co.uk</a>.
                    </p>
                </Alert>
            : null}

            <Form.Group className="mb-3">
                <Form.Label className="message-name" htmlFor="message-name">Name</Form.Label>
                <Form.Control onChange={e => setName(e.target.value)} name="name" className="message-name" type="text" placeholder="" value={name}/>
            </Form.Group>
            
            <Form.Group className="mb-3">
                <Form.Label className="message-email" htmlFor="message-email">Email</Form.Label>
                <Form.Control onChange={(e) => setEmail(e.target.value)} name="email" className="message-email" type="email" placeholder="your@email.com" required value={email} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="message-phone" htmlFor="message-phone">Phone</Form.Label>
                <Form.Control onChange={(e) => setPhone(e.target.value)} name="phone" className="message-phone" type="text" placeholder="" required value={phone} />
            </Form.Group>

            <Form.Group>
                <Form.Label className="message" htmlFor="message-input">Tell us about your project</Form.Label>
                <Form.Control as="textarea" onChange={e => setMessage(e.target.value)} name="message" className="message-input" type="text" placeholder="" value={message} required/>
            </Form.Group>

            <div className="mt-4">
                <Button type="submit" variant="primary" disabled={isButtonDisabled}>{ buttonText }</Button>
            </div>
        </Form>
    )
}
